import "core-js/modules/es.array.push.js";
import * as local from '@/libs/local';
//import HelloWorld from '@/components/HelloWorld.vue';
import { showToast } from 'vant';
import Vue from 'vue';
import { Swipe, SwipeItem } from 'vant';
import { Tabbar, TabbarItem } from 'vant';
import { Grid, GridItem } from 'vant';
import * as api from '@/api';
import { ref } from 'vue';
import { Tab, Tabs } from 'vant';
import { Col, Row } from 'vant';
import { Popup } from 'vant';
import { Cell, CellGroup } from 'vant';
import { Dialog } from 'vant';
import { NoticeBar } from 'vant';
import { NavBar } from 'vant';
import { Picker } from 'vant';
import { Empty } from 'vant';
import { BackTop } from 'vant';
import { Notify } from 'vant';
import { Badge } from 'vant';
import { Popover } from 'vant';
import { showNotify, closeNotify } from 'vant';
import 'vant/es/notify/style';
import 'vant/es/toast/style';
import 'vant/es/dialog/style';
// 引入英文语言包

import Header from '../lyout/header.vue';
import Footer from '../lyout/footer.vue';
import Ce from '../lyout/ce.vue';
export default {
  name: 'Home',
  components: {
    Header,
    Footer,
    Ce
  },
  data() {
    return {
      search_value: '',
      lang_list: {},
      select_lang: {},
      lang: {},
      cate: [],
      user: {},
      banner: [],
      goods: [],
      news: [],
      user_name: '',
      pass: '',
      r_user_name: '',
      r_pass: '',
      r_zfpass: '',
      code: '',
      loading: false,
      finished: false,
      is_jia: false,
      page: 1,
      order: [],
      count: 0,
      type: 0,
      app_root: api.base(),
      showPopover: false
    };
  },
  created: function () {
    let _this = this;
    _this.get_imdex_data();
    this.code = this.$route.query.code ? this.$route.query.code : '0000';
  },
  methods: {
    //切换语言
    select(e) {
      local.saveInfo('setting_lang', e.lang);
      window.location.reload();
    },
    out: function () {
      let _this = this;
      local.deleteInfo('jwt');
      _this.$router.push({
        name: 'login',
        query: {
          id: 0
        }
      });
    },
    onload: function (name = false) {
      if (this.is_jia) {
        return false;
      }
      this.is_jia = true;
      if (name) {
        this.order = [];
        this.page = 1;
        this.finished = false;
        this.is_has = 1;
      }
      api.all('/api/user/user_order_list', {
        page: this.page,
        type: this.type
      }, (err, data) => {
        if (!err && data.code === 1) {
          this.loading = false;
          this.is_jia = false;
          let oldArr = this.order;
          let newArr = data.data.order;
          this.order = oldArr.concat(newArr);
          this.count = data.data.count;
          if (data.data.count <= this.page * 20) {
            this.finished = true;
          }
          this.page++;
        } else {
          //console.log(data);
        }
      });
    },
    chat: function (type) {
      api.chat(type);
    },
    tab: function (type) {
      this.type = type;
      this.onload(true);
    },
    get_imdex_data: function () {
      api.all('/api/user/user_home', {}, (err, data) => {
        if (!err && data.code === 1) {
          this.lang_list = data.lang_list;
          this.select_lang = data.select_lang;
          this.lang = data.data.lang;
          this.cate = data.data.cate;
          this.user = data.data.user;
        } else {
          //console.log(data);
        }
      });
    },
    qian() {
      api.all('/api/user/user_card', {
        gz_id: 12
      }, (err, data) => {
        if (!err && data.code === 1) {
          //console.log('login_ok');
          this.get_imdex_data();
          showNotify({
            type: 'success',
            message: data.msg
          });
        } else {
          showNotify(data.msg);
        }
      });
    },
    quxiao: function (item) {
      let _this = this;
      api.all('/api/user/not', item, (err, data) => {
        if (!err) {
          showToast(data.msg);
          if (data.code == 1) {
            _this.onload(true);
          }
        }
      });
    },
    to: function (id1) {
      let _this = this;
      _this.$router.push({
        name: id1,
        query: {
          id: 0
        }
      });
      this.$nextTick(() => {
        window.scrollTo(0, 0); // 滚动到顶部  
      });
    }
  }
};